<template>
  <div class="details">
    <div class="space"></div>
    <div class="detail">
        <div class="banner">
            <img :src="banner.imageUrl" alt="" style="width: 60%;">
            <div class="banner_info">
                <div>
                    <img :src="banner.remark" alt="" style="width: 70%;">
                </div>
                <div style="margin-top: 30px; font-size: 22px; font-weight: bold;"><p>{{ banner.title }}</p></div>
                <div style="margin-top: 30px; font-size: 16px;"><p>{{ banner.content }}</p></div>
            </div>
        </div>
        <div class="back" style="width: 80%; margin: 0 auto;">
            <div class="title">Background</div>
            <div class="content">{{ background }}</div>
        </div>
        <div class="back">
            <div class="title">Challenges</div>
            <img :src="challenges" alt="" style="width: 100%;">
        </div>
        <div class="back">
            <div class="title">Solution Architecture</div>
            <img :src="architecture" alt="" style="width: 100%;">
        </div>
        <div class="back" style="width: 80%; margin: 0 auto;">
            <div class="title">Highlights</div>
            <div class="highlights-row" v-for="(item, index) in highlights" :key="index">
                <div class="highlights-row-title">{{ item.title }}</div>
                <div class="highlights-row-content">{{ item.content }}</div>
            </div>
        </div>
        <div class="back">
            <div class="title">Camera Deployment Display</div>
            <img :src="display" alt="" style="width: 100%;">
        </div>
        <div class="back" style="width: 950px; margin: 0 auto;">
            <div class="title">Main Products</div>
            <div class="card-panl">
                <div class="card-panl-title">{{ productsKey[0] | filterTitle}}</div>
                <div class="panl">
                    <div class="product-item" v-for="(item, index) in products[productsKey[0]]" :key="'s'+index" @click="openPage(item.link)">
                        <div class="product-img">
                            <img :src="item.imageUrl" alt="">
                        </div>
                        <div class="product-info">
                            <div class="product-title">{{ item.title }}</div>
                            <div class="product-introduction">{{ item.content }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <el-row :gutter="10" style="margin-top: 10px;">
                <el-col :span="12">
                    <div class="card-panl" v-if="productsKey[1] && productsKey[1].length > 0">
                        <div class="card-panl-title">{{ productsKey[1] | filterTitle}}</div>
                        <div class="panl">
                            <div class="product-item" style="width: 100%;" v-for="(item, index) in products[productsKey[1]]" :key="'c'+index" @click="openPage(item.link)">
                                <div class="product-img">
                                    <img :src="item.imageUrl" alt="">
                                </div>
                                <div class="product-info">
                                    <div class="product-title">{{ item.title }}</div>
                                    <div class="product-introduction">{{ item.content }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="card-panl" v-if="productsKey[2] && productsKey[2].length > 0">
                        <div class="card-panl-title">{{ productsKey[2] | filterTitle}}</div>
                        <div class="panl">
                            <div class="product-item" style="width: 100%;" v-for="(item, index) in products[productsKey[2]]" :key="'y'+index" @click="openPage(item.link)">
                                <div class="product-img">
                                    <img :src="item.imageUrl" alt="">
                                </div>
                                <div class="product-info">
                                    <div class="product-title">{{ item.title }}</div>
                                    <div class="product-introduction">{{ item.content }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="back">
            <div class="title">Main Technologies</div>
            <img :src="technologies" alt="" style="width: 100%;">
        </div>
        <div class="back">
            <div class="title">Partner Information</div>
            <div class="info-row" v-for="(item, index) in information" :key="index">
                <div class="info-icon"><img :src="item.imageUrl" alt=""></div>
                <div class="info-content">{{ item.content }}</div>
            </div>
        </div>
        <div class="back">
            <div class="title">Voice of Partner</div>
            <div style="font-size: 16;">
                <i style="font-size: 16px; line-height: 28px;"><strong style="font-size: 24px;">"</strong>{{ voice.content }} <strong style="font-size: 24px;">"</strong></i>
                <p style="text-align: right; margin-top: 25px;">———— 
                    <span style="font-weight: bold; font-size: 18px;">{{ voice.remark }}</span>
                </p>
                <p style="text-align: right; font-weight: bold;">{{ voice.title }}</p>
            </div>
        </div>
        <template v-if="docs.length > 0">
            <div class="section-title">Related Resources</div>
            <div class="documents">
                <div class="document" v-for="(item) in docs" :key="item.id">
                    <div class="resouse-name">{{ item.docName }}</div>
                    <div class="resouse-link" @click="download(item.link)">{{ $i18n.t('productDetail.clicktoLoad' )}}</div>
                </div>
            </div>
        </template>
    </div>
  </div>
</template>

<script>
import {getCaseContentByCaseId, getCaseDocByCaseId} from '@/api/caseApi'
export default {
name: 'CaseDetails',
data() {
    return {
        id: null,
        banner: {
            imageUrl: '',
            remark: '',
            title: '',
            content: ''
        },
        background: null,
        challenges: null,
        architecture: null,
        highlights: [],
        display: null,
        products: {},
        productsKey: [],
        technologies: null,
        information: [],
        voice: {
            content: '',
            remark: '',
            title: ''
        },
        docs: []
    }
},
mounted() {
    this.id = this.$route.params.id;
    this.getData()
    this.getDocsByCaseId()
},
filters: {
    filterTitle(value) {
        if (value) {
            let str = value.split('.');
            return str.length > 0 ? str[1] : value;
        }
        return value;
    }
},
methods: {
    download(url) {
        window.open(url)
    },
    openPage(url) {
        window.open(url)
    },
    async getData() {
        const {data: {data, isSuccess}} = await getCaseContentByCaseId({id: this.id})
        if (isSuccess) {
            // console.log(data)
            for (let item of data) {
                if ("banner" === item.type) {
                    this.banner = item
                }
                if ("background" === item.type) {
                    this.background = item.content
                }
                if ("challenges" === item.type) {
                    this.challenges = item.imageUrl
                }
                if ("solution architecture" === item.type) {
                    this.architecture = item.imageUrl
                }
                if ("highlights" === item.type) {
                    this.highlights.push({title: item.title, content: item.content})
                }
                if ("camera deployment display" === item.type) {
                    this.display = item.imageUrl
                }
                if ("main products" === item.type) {
                    (this.products[item.remark] || (this.products[item.remark] = [])).push(item)
                }
                if ("main technologies" === item.type) {
                    this.technologies = item.imageUrl
                }
                if ("partner information" === item.type) {
                    this.information.push({imageUrl: item.imageUrl, content: item.content})
                }
                if ("voice of partner" === item.type) {
                    this.voice = item
                }
            }
            this.productsKey = Object.keys(this.products)
            //.map(item => item.split('.')[1])
        }
    },
    async getDocsByCaseId() {
        const {data: {data, isSuccess}} = await getCaseDocByCaseId({
            caseId: this.id
        })
        if (isSuccess) {
            this.docs = data;
        }
    }
}
}
</script>

<style lang="less" scoped>
.details {
    padding-bottom: 100px;
    .space {
        border-top: 1px solid #f4f8f9;
    }
    .detail {
        width: 1080px;
        margin: auto;
        margin-top: 50px;
        .banner {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .banner_info {
                width: 40%;
                padding-left: 50px;
                text-align: right;
                white-space: break-spaces;
            }
        }
        .back {
            margin-top: 70px !important;
            .content {
                // word-break: normal;
                // color: #808080;
                font-size: 18px;
                white-space: break-spaces;
            }
            .highlights-row + .highlights-row {
                margin-top: 40px;
            }
            .highlights-row-title {
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 5px;
            }
            .highlights-row-content {
                font-size: 18px;
                // color: #808080;
                white-space: break-spaces;
            }
            .card-panl {
                background-color: #f8f8f8;
                padding: 15px;
                .card-panl-title {
                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;
                    padding-bottom: 15px;
                    border-bottom: 1px solid rgba(128, 128, 128, 0.397);
                }
                .panl {
                    display: flex;
                    flex-wrap: wrap;
                    .product-item {
                        cursor: pointer;
                        &:nth-child(2n) {
                            margin-left: 40px;
                        }
                        padding: 10px;
                        display: flex;
                        width: calc(50% - 20px);
                        align-items: center;
                        justify-content: flex-start;
                        .product-img {
                            height: 100px;
                            width: 140px;
                            flex-shrink: 0;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .product-info {
                            .product-title {
                                font-size: 18px;
                                // color: #808080;
                            }
                            .product-introduction {
                                font-size: 18px;
                                color: #aaa;
                            }
                        }
                    }
                }
            }
            .info-row {
                & + .info-row {
                    margin-top: 20px;
                }
                .info-icon {
                    text-align: center;
                    img {
                        width: 200px;
                        height: 100px;
                    }
                }
                .info-content {
                    // color: #808080;
                    font-size: 18px;
                    white-space: break-spaces;
                }
            }
        }
        .title {
            font-size: 25px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 30px;
        }
    }

    .section-title {
        height: 40px;
        line-height: 40px;
        font-size: 30px;
        color: #000;
        border-left: 6px solid #c7000b;
        padding-left: 32px;
        margin-bottom: 30px;
        margin-top: 60px;
    }
    .documents {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .document {
            width: 100%;
            height: 40px;
            padding-left: 40px;
            // padding-right: 60px;
            position: relative;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            &::before {
                position: absolute;
                left: 0px;
                top: 15px;
                content: '';
                width: 10px;
                height: 10px;
                background-color: gray;
                border-radius: 50%;
            }
            margin-bottom: 20px;
            .resouse-name {
                font-size: 16px;
                min-width: 500px;
            }
            .resouse-link {
                cursor: pointer;
                border: 1px solid #c7000b;
                padding: 5px 11px 5px 11px;
                border-radius: 224px;
                color: #c7000b;
                font-size: 16px;
                flex-shrink: 0;
            }
        }
    }
}
</style>